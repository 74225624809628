html{
  height:100%;
  width:100%
}

body{
  height:100%;
  margin:auto;
  font-size:14px;
  max-width:none;
  color:#577786;
  font-weight:400;
  line-height:1.8;
  font-family:"neue-haas-unica","pragmatica-web","Helvetica Neue",Helvetica,Arial,sans-serif
}

figure{
  margin:0;
  padding:0
}

#appContent{
  height:100%
}

{}