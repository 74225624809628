article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section{
  display:block
}

body{
  line-height:1
}

ul{
  list-style:none
}

button{
  font-family:inherit
}